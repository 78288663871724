import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Image from './image';

const Header = ({ navigation = [] }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    <div
      className="flex justify-between items-end"
      style={{
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <Image />

      <nav>
        {navigation.map(({ name, link }) => (
          <Link className="ml-8" to={link} key={name}>
            {name}
          </Link>
        ))}
      </nav>
    </div>
  </header>
);

Header.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Header;
