import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SEO from '../components/seo';

const extractTags = post =>
  post.tags ? post.tags.map(tag => tag.name).join(', ') : 'none';

const Post = ({ data }) => {
  const post = data.wordpressPost;
  return (
    <Layout>
      <SEO title={post.title} keywords={post.tags || []} />
      <div className="flex">
        <div className="flex-1">
          {/* eslint-disable-next-line react/no-danger */}
          <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          <p className="font-sm">{post.date}</p>
          <p>tags: {extractTags(post)} </p>
        </div>
        <div
          className="flex-2"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      tags {
        name
      }
      date(formatString: "Do MMMM YYYY")
      content
    }
  }
`;

Post.propTypes = {
  data: PropTypes.shape({
    wordpressPost: PropTypes.shape({
      title: PropTypes.node,
      content: PropTypes.node,
      date: PropTypes.string,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
};

export default Post;
