import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/layout';

const Page = ({ data }) => {
  const page = data.wordpressPage;
  return (
    <Layout>
      <div>
        {/* eslint-disable-next-line react/no-danger */}
        <h1 dangerouslySetInnerHTML={{ __html: page.title }} />
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: page.content }} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
  }
`;

Page.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      title: PropTypes.node,
      content: PropTypes.node,
    }),
  }).isRequired,
};

export default Page;
